import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import App from './App.vue'

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: 'https://5ef30cdbfb2344bea39e51198580f7ec@o582423.ingest.sentry.io/5736696',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
})

new Vue({
  render: h => h(App),
}).$mount('#app')
