<template>
    <div class="bg-white inline-block align-top shadow-lg rounded-lg overflow-hidden mb-4">
        <div class="sm:flex sm:items-center px-6 py-4">
            <div class="text-center sm:text-left sm:flex-grow">
                <div class="border-t border-b border-grey-light">
                    <table class="w-full">
                        <thead>
                            <tr>
                                <th class="text-sm font-semibold py-2 px-10" :class="{ 'text-grey-darkest': player.color.isLight(), 'text-white': !player.color.isLight() }" :style="{ backgroundColor: player.color.rgb().string() }" v-for="(player, index) in players" :key="index">
                                    <span :class="{ 'font-bold': index === currentPlayer }">
                                        Player {{ index + 1 }}
                                        <span v-if="player.isAi">(AI)</span>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <td class="py-1 pl-1 border-t border-grey-light text-right text-lg" v-for="(player, index) in players" :key="index">
                                    <strong>{{ player.getTotalScore() }}</strong>
                                </td>
                            </tr>
                        </tfoot>
                        <tbody>
                            <tr v-for="(a, row) in scoreRows" :key="row">
                                <td class="py-1 pl-1 border-t border-grey-light text-right" v-for="(player, index) in players" :key="index">
                                    <template v-if="typeof player.scores[row] !== 'undefined'">
                                        {{ player.scores[row] || '-' }}
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'Scoreboard',
    props: {
      players: {
        type: Array,
        required: true
      },
      currentPlayer: {
        type: Number,
        required: true
      }
    },
    computed: {
      scoreRows () {
        return new Array(Math.max(...this.players.map(p => p.scores.length)))
      }
    }
  }
</script>
