export default class Player {
  constructor (color) {
    this.scores = []
    this.color = color
  }

  addScore (value) {
    this.scores.push(value)
  }

  getTotalScore () {
    return this.scores.reduce((t, s) => t + s, 0)
  }
}
