<template>
    <a class="dice" :class="classes" :style="styles">
        <div class="pip"></div>
        <div class="pip"></div>
        <div class="pip"></div>
        <div class="pip"></div>
        <div class="pip"></div>
        <div class="pip"></div>
        <div class="pip"></div>
        <div class="pip"></div>
        <div class="pip"></div>
    </a>
</template>

<script>
  export default {
    name: 'Dice',
    props: {
      data: {
        type: Object,
        required: true
      },
      size: {
        type: Number,
        default: 50
      }
    },
    computed: {
      classes () {
        const classes = [`dice--${this.data.value}`]

        if (this.data.locked) {
          classes.push('dice--locked')
        }

        if (this.data.selected) {
          classes.push('dice--selected')
        }

        return classes
      },
      styles () {
        return {
          width: `${this.size}px`,
          height: `${this.size}px`
        }
      }
    }
  }
</script>

<style scoped lang="scss">
    .dice {
        position: relative;
        display: inline-block;
        margin-right: 1em;
        box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.3);
        border: 2px solid #ffffff;
        border-radius: 10%;
        background: #ffffff;
        opacity: 1;
        transform: scale(1) rotateZ(0);
        transition: opacity 0.2s, transform 0.2s;
        cursor: pointer;

        &:nth-child(1) { transform: rotateZ(10deg); }
        &:nth-child(2) { transform: rotateZ(-5deg); }
        &:nth-child(3) { transform: rotateZ(15deg); }
        &:nth-child(4) { transform: rotateZ(5deg); }
        &:nth-child(5) { transform: rotateZ(-10deg); }
        &:nth-child(6) { transform: rotateZ(5deg); }

        .pip {
            display: none;
            position: absolute;
            width: 16%;
            height: 16%;
            border-radius: 50%;
            background: #000000;

            &:nth-child(1), &:nth-child(2), &:nth-child(3) {
                top: 16%;
            }

            &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                top: 43%;
            }

            &:nth-child(7), &:nth-child(8), &:nth-child(9) {
                top: 69%;
            }

            &:nth-child(3n - 2) {
                left: 16%;
            }

            &:nth-child(3n - 1) {
                left: 43%;
            }

            &:nth-child(3n) {
                left: 69%;
            }
        }

        &--1 {
            .pip:nth-child(5) {
                display: block;
            }
        }

        &--2 {
            .pip:nth-child(3), .pip:nth-child(7) {
                display: block;
            }
        }

        &--3 {
            .pip:nth-child(3), .pip:nth-child(5), .pip:nth-child(7) {
                display: block;
            }
        }

        &--4 {
            .pip:nth-child(1), .pip:nth-child(3), .pip:nth-child(7), .pip:nth-child(9) {
                display: block;
            }
        }

        &--5 {
            .pip:nth-child(1), .pip:nth-child(3), .pip:nth-child(5), .pip:nth-child(7), .pip:nth-child(9) {
                display: block;
            }
        }

        &--6 {
            .pip:nth-child(1), .pip:nth-child(3), .pip:nth-child(4), .pip:nth-child(6), .pip:nth-child(7), .pip:nth-child(9) {
                display: block;
            }
        }

        &--selected {
            border-color: #0000ff;
            box-shadow: 1px 2px 20px 0px rgba(0, 0, 0, 0.5);
            transform: scale(1.1) rotateZ(0) !important;
        }

        &--locked {
            transform: scale(0.9) rotateZ(0) !important;
            opacity: 0.4;
        }
    }
</style>
