export function double (num, times) {
  for (let i = 0; i < times; ++i) {
    num *= 2
  }

  return num
}

export function score (dice) {
  let counts = []
  let score = 0

  if (dice.length < 1 || dice.length > 6) {
    return false
  }

  if (dice.some(d => !Number.isInteger(parseInt(d)) || d < 1 || d > 6)) {
    return false
  }

  for (let i = 1; i <= 6; ++i) {
    counts.push({
      d: i,
      c: dice.filter(d => d == i).length
    })
  }

  // Six Straight
  if (!counts.some(count => count.c < 1)) {
    return {
      score: 1500,
      surplus: 0,
      scoringDice: counts
    }
  }

  // Three pairs
  if (counts.filter(count => count.c === 2).length === 3) {
    return {
      score: 500,
      surplus: 0,
      scoringDice: counts
    }
  }

  let scoringDice = counts.slice().map(count => {
    count = Object.assign({}, count)
    count.c = 0

    return count
  })

  // Five Straight
  if (counts.filter(count => count.c > 0).length === 5) {
    const straight = counts.filter(count => count.c > 0)
    let prev = null

    const valid = straight.every(count => {
      if (prev === null || prev + 1 === count.d) {
        prev = count.d

        return true
      }

      return false
    })

    if (valid) {
      score += 1000

      straight.forEach(c => {
        counts.find(count => count.d === c.d).c -= 1
        scoringDice.find(count => count.d === c.d).c += 1
      })
    }
  }

  // Three
  // Four
  // Five
  for (let index in counts) {
    const multiplier = counts[index].d === 1 ? 100 : 10

    if (counts[index].c >= 3) {
      score += double(counts[index].d * multiplier * 10, counts[index].c - 3)
      scoringDice[index].c = counts[index].c
      counts[index].c = 0

      continue
    }

    if ([1, 5].indexOf(counts[index].d) !== -1 && counts[index].c > 0) {
      score += (counts[index].d * counts[index].c) * multiplier
      scoringDice[index].c = counts[index].c
      counts[index].c = 0
    }
  }

  return {
    score,
    scoringDice,
    surplus: counts.reduce((t, c) => {
      return t + c.c
    }, 0)
  }
}
