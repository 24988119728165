<template>
    <div>
        <!--<div class="bust fixed flex items-center pin z-10 uppercase font-bold text-red-dark pointer-events-none">
            <p class="flex-grow text-center">Bust</p>
        </div>-->

        <div class="bg-white inline-block align-top shadow-lg rounded-lg overflow-hidden mb-4 mr-4" :class="{ 'text-grey-darkest': actingPlayer.color.isLight(), 'text-white': !actingPlayer.color.isLight() }" :style="{ backgroundColor: actingPlayer.color.rgb().string() }" v-if="currentPlayer">
            <div class="sm:flex sm:items-center px-6 py-4">
                <div class="text-center sm:text-left sm:flex-grow">
                    <p class="font-bold mb-4">Player {{ currentPlayerId + 1 }}'s turn</p>

                    <div class="mb-4">
                        <die :data="die" @click.native="select(die)" :key="index" v-for="(die, index) in orderedDice"></die>
                    </div>

                    <p class="font-bold mb-4" v-if="selectedScore && selectedScore.surplus > 0"><i class="fas fa-exclamation-triangle"></i> Invalid selection</p>

                    <button class="bg-blue hover:bg-blue-dark text-white font-bold py-2 px-4 rounded mr-2 mb-4" @click="roll()">Roll</button>
                    <button class="bg-green hover:bg-green-dark text-white font-bold py-2 px-4 rounded mr-2 mb-4" @click="bank()">Bank</button>

                    <p class="text-lg font-bold mb-4" v-if="roundBust"><i class="fas fa-poop"></i> You're bust!</p>
                    <p>
                        Round Score: <strong>{{ roundScore }}</strong><br>
                        <span v-if="selectedScore"><strong>+{{ selectedScore.score }}</strong></span>
                    </p>
                </div>
            </div>
        </div>

        <scoreboard :players="players" :current-player="currentPlayerId" v-if="players"></scoreboard>

        <div class="bg-white max-w-xl shadow-lg rounded-lg overflow-hidden mb-4 mr-4">
            <div class="sm:flex sm:items-center px-6 py-4">
                <div class="text-center sm:text-left sm:flex-grow relative">
                    <p class="text-red mb-4"><strong>Warning!</strong> Changing the options below with restart the game</p>
                    <div class="absolute pin-t pin-r">
                        <i class="fas fa-lock mr-1"></i>
                        <input type="checkbox" v-model="optionsLocked" />
                    </div>

                    <div class="flex flex-wrap -mx-3 mb-2">
                        <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="playerCount">
                                Players
                            </label>
                            <input class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-grey" id="playerCount" type="number" step="1" min="1" max="100" v-model.number="playerCount" :disabled="optionsLocked">
                        </div>
                        <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="aiCount">
                                Ai
                            </label>
                            <input class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-grey" id="aiCount" type="number" step="1" min="1" max="100" v-model.number="aiCount" :disabled="optionsLocked">
                        </div>
                        <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="aiWaitTime">
                                Ai thinking time (ms)
                            </label>
                            <input class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-grey" id="aiWaitTime" type="number" step="1" min="0" v-model.number="aiWaitTime" :disabled="optionsLocked">
                            <p class="text-grey-darker text-xs mt-1">Note: Doesn't affect AI decisions, just how quickly they act to give you time to see what they're doing</p>
                        </div>
                        <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="minStartScore">
                                Minimum start score
                            </label>
                            <input class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-grey" id="minStartScore" type="number" step="1" min="0" v-model.number="minStartScore" :disabled="optionsLocked">
                        </div>
                        <div class="w-full md:w-1/5 px-3 mb-6 md:mb-0">
                            <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="targetScore">
                                Target score
                            </label>
                            <input class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-grey" id="targetScore" type="number" step="1" min="1000" v-model.number="targetScore" :disabled="optionsLocked">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import Ai from '../models/Ai'
  import Die from './Die.vue'
  import Player from '../models/Player'
  import Scoreboard from './Scoreboard.vue'
  import color from 'color'
  import colors from '../colors'
  import {score} from '../functions'
  import map from 'lodash/map'

  export default {
    name: 'Greed',
    data: () => ({
      optionsLocked: false,
      minStartScore: 500,
      targetScore: 10000,
      aiWaitTime: 250,
      playerCount: 1,
      aiCount: 1,
      roundScore: 0,
      roundRolls: 0,
      roundBust: false,
      currentPlayerId: 0,
      players: null,
      dice: new Array(6).fill().map(() => ({
        value: 1,
        locked: false,
        selected: false
      }))
    }),
    computed: {
      selected () {
        return this.dice.filter(die => die.selected)
      },
      selectedScore () {
        return score(map(this.selected, 'value'))
      },
      selectionValid () {
        return !((this.roundRolls > 0 && !this.selectedScore) || this.selectedScore.surplus > 0)
      },
      orderedDice () {
        return this.dice.slice().sort((a, b) => {
          if (a.locked === b.locked) {
            return 0
          }

          if (a.locked) {
            return -1
          }

          return 1
        })
      },
      isAiTurn () {
        if (!this.players) {
          return false
        }

        return this.currentPlayer.isAi
      },
      currentPlayer () {
        if (!this.players) {
          return null
        }

        return this.players[this.currentPlayerId]
      },
      nextPlayer () {
        let newPlayer = this.currentPlayerId + 1

        if (typeof this.players[newPlayer] === 'undefined') {
          newPlayer = 0
        }

        return this.players[newPlayer]
      },
      actingPlayer () {
        if (this.roundBust) {
          return this.nextPlayer
        }

        return this.currentPlayer
      }
    },
    methods: {
      select (die, ai = false) {
        if ((this.isAiTurn && !ai) || this.roundRolls < 1) {
          return
        }

        if (die.locked) {
          return
        }

        die.selected = !die.selected
      },
      roll (ai = false) {
        if ((this.isAiTurn && !ai) && !this.roundBust) {
          return
        }

        this.optionsLocked = true

        if (this.roundBust) {
          this.roundBust = false
          this.resetRound()
          this.cyclePlayer()
        }

        if (!this.selectionValid) {
          alert('Invalid selection')

          return
        }

        ++this.roundRolls

        if (this.selectedScore) {
          this.roundScore += this.selectedScore.score
        }

        if (this.dice.filter(die => !die.locked && !die.selected).length < 1) {
          this.dice.forEach(die => {
            die.locked = false
            die.selected = false
          })
        }

        this.dice.forEach(die => {
          if (die.selected) {
            die.locked = true
            die.selected = false
          }

          if (die.locked) {
            return
          }

          die.value = Math.floor(Math.random() * 6) + 1
        })

        const possibleScore = score(map(this.dice.filter(die => !die.locked), 'value'))

        if (!possibleScore || possibleScore.score <= 0) {
          this.roundScore = 0
          this.currentPlayer.addScore(this.roundScore)
          if (this.nextPlayer.isAi) {
            this.$nextTick(() => {
              this.cyclePlayer()
              this.resetRound()
              this.takeAiTurn()
            })

            return false
          }

          this.roundBust = true

          return false
        }
      },
      bank (ai = false) {
        if (this.isAiTurn && !ai) {
          return
        }

        if (!this.selectionValid) {
          alert('Invalid selection')

          return
        }

        const turnScore = this.roundScore + this.selectedScore.score

        if (this.currentPlayer.getTotalScore() <= 0 && turnScore < this.minStartScore) {
          alert('Minimum score requirements not met')

          return
        }

        this.currentPlayer.addScore(turnScore)

        if (this.currentPlayer.getTotalScore() >= this.targetScore) {
          alert(`Player ${this.currentPlayerId + 1} wins!`)
        }

        this.resetRound()
        this.cyclePlayer()

        if (this.currentPlayer.isAi) {
          this.takeAiTurn()
        }
      },
      cyclePlayer () {
        let newPlayer = this.currentPlayerId + 1

        if (typeof this.players[newPlayer] === 'undefined') {
          newPlayer = 0
        }

        this.currentPlayerId = newPlayer
      },
      resetRound () {
        this.roundScore = 0
        this.roundRolls = 0
        this.dice = this.dice.map(() => ({
          value: 1,
          locked: false,
          selected: false
        }))
      },
      resetPlayerScores () {
        const players = new Array(this.playerCount).fill().map((p, i) => new Player(color(colors[i % colors.length])))

        this.players = players.concat(new Array(this.aiCount).fill().map((v, i) => new Ai(i, this, color(colors[(this.playerCount + i) % colors.length]))))
      },
      reset (force = false) {
        if (force || confirm('Are you sure you want to reset?')) {
          this.resetRound()

          this.currentPlayerId = 0
          this.resetPlayerScores()
        }
      },
      takeAiTurn () {
        this.currentPlayer.takeTurn()
      }
    },
    watch: {
      minStartScore () {
        this.reset(true)
      },
      targetScore () {
        this.reset(true)
      },
      playerCount () {
        this.reset(true)
      },
      aiCount () {
        this.reset(true)
      }
    },
    mounted () {
      this.resetPlayerScores()

      if (this.currentPlayer.isAi) {
        this.takeAiTurn()
      }
    },
    components: {
      Die,
      Scoreboard
    }
  }
</script>

<style scoped>
    .bust {
        font-family: 'Knewave', cursive;
        font-size: 8rem;
        transform: rotateZ(-45deg);
    }
</style>
