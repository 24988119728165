import Player from './Player'
import {score} from '../functions'
import map from 'lodash/map'

export default class Ai extends Player {
  constructor (playerIndex, vue, color) {
    super(color)
    this.vue = vue
    this.isAi = true
    this.playerIndex = playerIndex
  }

  takeTurn () {
    this._debug('Taking my turn')
    this._takeTurnAsync()
  }

  async _takeTurnAsync () {
    await this._delay()

    await this._turnDoRoll()
  }

  async _turnDoRoll () {
    this._debug('rolling')
    const res = this.vue.roll(true)

    const bestScore = score(map(this.vue.dice.filter(die => !die.locked), 'value'))

    if (res === false || !bestScore || bestScore.score <= 0) {
      this._debug('BUST')

      return
    }

    await this._delay()

    const diceToSelect = bestScore.scoringDice.reduce((t, d) => t + d.c, 0)

    this._debug('selecting best', JSON.stringify(bestScore.scoringDice.filter(sd => sd.c > 0)), bestScore.score)

    const dice = this.vue.dice.filter(die => !die.locked)

    for (let die in dice) {
      if (!dice.hasOwnProperty(die)) {
        return
      }

      die = dice[die]

      const scoringDie = bestScore.scoringDice.find(sd => sd.d === die.value)

      if (scoringDie.c > 0) {
        this._debug('selecting die', scoringDie.d)
        this.vue.select(die, true)
        --scoringDie.c
        await this._delay(this.vue.aiWaitTime / diceToSelect)
      }
    }

    await this._delay()

    const turnScore = this.vue.roundScore + this.vue.selectedScore.score
    const remainingDice = this.vue.dice.filter(die => !die.locked && !die.selected)

    if (this.getTotalScore() + turnScore >= this.vue.targetScore) {
      this._debug('banking')
      this.vue.bank(true)

      return
    }

    if (remainingDice.length > 2 || remainingDice.length === 0 || (this.getTotalScore() <= 0 && turnScore < this.vue.minStartScore)) {
      await this._turnDoRoll()
    } else {
      this._debug('banking')
      this.vue.bank(true)
    }
  }

  _delay (ms = null) {
    this._debug('sleeping', ms || this.vue.aiWaitTime)
    return new Promise(res => setTimeout(res, ms || this.vue.aiWaitTime))
  }

  _debug () {
    // eslint-disable-next-line no-console
    console.log(this.playerIndex, ...arguments)
  }
}
