<template>
    <div id="app">
        <greed></greed>

        <div class="mt-4 pt-4 border-t border-grey-light">
            <h2 class="mb-2">Greed</h2>

            <p class="mb-2">Greed or 10,000 is probably one of the most popular dice games. The <strong>Greed dice game rules</strong> are easy and you can play this game anytime and everywhere. All you need is a set of dice and some friends.</p>

            <h3 class="mb-2">Rules</h3>

            <p class="mb-2">As said before, the greed dice game rules are straightforward. These are the main steps in the game:</p>

            <ol class="mb-2">
                <li>Decide who will start: you can do this by having each player roll a dice (or a number of dice). The player with the highest score starts the game. You then proceed clockwise to the next player.</li>
                <li>A player's turn starts by rolling all six dice - the following scenarios then exist:
                    <ul>
                        <li>The player did not roll any 1', 5's or scoring combination (see further below for an overview of most scoring combinations): the turn of that player ends with 0 points and the next player rolls the dice.</li>
                        <li>The player rolled a scoring combination:
                            <ul>
                                <li>He can choose to keep that score and add it to this overall score. The turn of the player ends.</li>
                                <li>He can choose to roll all six dice again and try to get a better score - but he loses the points of his first roll!</li>
                                <li>He can choose to set aside the dice of the scoring combination and roll the remaining dice again to try and get additional points. The dice set aside cannot be used again to make a combination with the dice rolled. If the player rolls the remaining dice again and the combination of the remaining dice has no score, his turn ends with 0 points.</li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>To start adding any points to his or her overall score, a player needs to have scored the minimum required score in a single turn. This can be 500 points for example. So if a player scores only 400 points in his first turn, he cannot add any points to his score. If he then rolls 600 points in the next turn, he can add the 600 points to his score. In all later turns of that game, the player can add any points the scored to his overall score.</li>
                <li>The game ends when a player has reached 10,000 points. There are a number of variants that you can choose from to decide who wins the game:
                    <ul>
                        <li>The first player to reach 10,000 points or more is the winner of the game;</li>
                        <li>When a player reaches 10,000 points or more, all other players have one more turn. If another player reaches a higher score, he or she is the winner of the game;</li>
                        <li>The first player to reach an exact 10,000 points is the winner of the game.</li>
                    </ul>
                </li>
            </ol>

            <h3 class="mb-2">Scoring</h3>

            <ul class="mb-2">
                <li>Each 1 that you roll is worth 100 points</li>
                <li>Each 5 that you roll is worth 50 points</li>
                <li>Three of a kind is worth 100 points multiplied by the number on the dice. So, for example, three of a kind of 5's is worth 500 points. Each additional dice will then double the score, so for example, if you roll four 5's then you will have 1000 (500x2) points. If you roll five 5's them you have 2000 (1000x2) points</li>
                <li>Three of a kind of 1's is worth 1000 points. The same rule applies for additional 1's: 4x1 is 2000 points, 5x1 is 4000 points and 6x1 is 8000 points</li>
                <li>Three pairs is worth 500 points</li>
                <li>A five dice straight (1-2-3-4-5 or 2-3-4-5-6) is worth 1000 points, while a six dice straight is worth 1500 points</li>
            </ul>
        </div>
    </div>
</template>

<script>
  import Greed from './components/Greed.vue'

  export default {
    name: 'app',
    components: {
      Greed
    }
  }
</script>

<style scoped>
    #app {
        font-family: 'Roboto', sans-serif;
    }
</style>
